<template>
  <span>
    <v-dialog v-model="dialog" persistent max-width="700">
      <v-card>
        <v-card-title class="headline"> Продажа товара </v-card-title>

        <v-card-text>
          <v-select
            v-bind:items="costtpes"
            v-model="filterCostTpe"
            label="Способ оплаты"
            bottom
            item-text="name"
            item-value="id"
          ></v-select>
          <v-row>
            <v-col>
              <v-autocomplete
                v-model="good"
                :loading="isLoading"
                :items="goodsFilter"
                item-text="name"
                hide-details
                label="Название товара"
                placeholder="Начните вводить название"
                return-object
                @input="good = []"
                :search-input.sync="searchGood"
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object' && true">
                    <v-list-item-title v-text="data.item"></v-list-item-title>
                  </template>

                  <template v-else>
                    <v-list-item-content @click="selGood(data.item)">
                      <v-list-item-title>
                        {{ data.item.name }}
                        <small
                          >Стоимость:<b>{{ data.item.cost }}</b> Остаток:<b>{{ data.item.quantity }}</b> Склад:<b>{{
                            data.item.storage_name
                          }}</b></small
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete> </v-col
            ><v-col sm="2">
              <v-btn color="info" @click="clickServiceModal = true">
                Выбрать
              </v-btn>
            </v-col>
          </v-row>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">Название</th>
                <th class="text-left" width="100px">Кол-во</th>
                <th class="text-left" width="200px">Стоимость</th>
                <th class="text-left" width="100px">Сумма</th>
                <th class="text-left" width="20px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(g, index) in goodItems" :key="g.good">
                <td>{{ g.name }}</td>
                <td>
                  <v-text-field
                    @change="saveGoodItems()"
                    single-line
                    hide-details
                    width="20px"
                    height="20"
                    v-model="g.quantity"
                  ></v-text-field>
                </td>
                <td>
                  <v-text-field
                    @change="saveGoodItems()"
                    hide-details
                    width="20px"
                    height="20"
                    v-model="g.cost"
                  ></v-text-field>
                </td>
                <td>{{ g.quantity * g.cost }}</td>
                <td>
                  <v-btn icon small @click="delGoodRow(index)"><v-icon>fa-trash-o</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="$emit('close')">Закрыть</v-btn>
          <v-btn color="primary" @click="save">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="clickServiceModal" scrollable>
      <v-card>
        <v-card-title class="headline">Выбор товаров</v-card-title>
        <v-card-text>
          <span v-if="clickServiceModalGrp == ''">
            <v-btn
              color="primary"
              v-for="(grp, idx) in groups"
              v-bind:key="idx"
              @click="clickServiceModalGrp = grp.id"
              outlined
              block
              >{{ grp.name }}</v-btn
            >
          </span>
          <span v-if="clickServiceModalGrp != ''">
            <v-btn
              color="primary"
              v-for="(item, idx) in groupServices"
              v-bind:key="idx"
              @click="selGood(item)"
              :outlined="!item.flg_sel"
              block
              >{{ item.name }} <v-spacer></v-spacer>{{ item.cost }}</v-btn
            >
          </span>
        </v-card-text>
        <v-card-actions>
          Выбрано товаров:{{ itemCost() }}
          <v-spacer></v-spacer>
          <v-btn text v-if="clickServiceModalGrp != ''" @click="clickServiceModalGrp = ''">
            Назад
          </v-btn>
          <v-btn
            text
            @click="
              clickServiceModalGrp = ''
              clickServiceModal = false
            "
          >
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
/*
todo: 
+кнопка выбрать
+обьединение одинаковых позиций
+проверка остатка
проверить остаток при ручном изменении
сохранение продажи
при вызове из заказа, показать уже проданные товары

*/
export default {
  props: ['flgOpen'],
  data() {
    return {
      dialog: false,
      good: [],
      goods: [],
      isLoading: false,
      searchGood: null,
      goodItems: [],
      clickServiceModal: false,
      clickServiceModalGrp: '',
      filterCostTpe: '',
    }
  },
  watch: {
    flgOpen(val) {
      this.dialog = val
      if (val) {
        this.loadGood('')
        this.goodItems = []
      }
    },
    searchGood(val) {
      var t = this
      this.isLoading = true
      t.loadGood(val)
    },
  },
  computed: {
    smena: function() {
      return this.$store.state.auth.smena
    },
    goodsFilter: function() {
      var t = this,
        g = '',
        ret = []
      t.goods.forEach(function(e) {
        if (g == '') {
          ret.push({
            header: e.group_name,
          })
          g = e.group
        } else if (g != '' && g != e.group) {
          ret.push({
            divider: true,
          })
          ret.push({
            header: e.group_name,
          })
          g = e.group
        }
        if (e.id != '') {
          e.service = e.id
          e.id = ''
        }
        //console.log('e', e);
        ret.push(e)

        //                console.log(e);
      })
      console.log('goods', ret)
      return ret
    },
    groups: function() {
      var t = this,
        g = '',
        ret = []
      t.goods.forEach(function(e) {
        if (g == '') {
          //                        console.log('item!!',item);
          ret.push({
            name: e.group_name,
            id: e.group,
          })
          g = e.group
        } else if (g != '' && g != e.group) {
          //                        console.log('item!!',item);
          ret.push({
            name: e.group_name,
            id: e.group,
          })
          g = e.group
        }
      })
      //                console.log('groups', ret);
      return ret
    },
    groupServices: function() {
      var t = this,
        // item = t.clickServiceModalItem
        ret = t.goods.filter(e => e.group == t.clickServiceModalGrp)
      ret.forEach(function(entry) {
        //                    console.log('item',item);
        entry.flg_sel = false
        if (t.goodItems.filter(e => e.good == entry.good).length > 0) {
          entry.flg_sel = true
        }
      })
      console.log('groupServices', ret)
      return ret
    },
    costtpes: function() {
      return this.$store.state.spr.account
    },
  },
  mounted: function() {
    var t = this
    this.loadGood('')
    this.$store.dispatch('loadAccount').then(() => {
      if (!t.filterCostTpe) {
        t.filterCostTpe = t.costtpes[0]['id']
      }
    })
  },
  methods: {
    selGood(e) {
      //выбор работы из списка
      var t = this
      // console.log('items', this.goods)
      // console.log('goodItems', this.goodItems)
      // console.log('e', e)
      var gi = this.goodItems.filter(a => a.good == e.good)
      if (gi.length > 0) {
        this.goodItems.splice(e, 1)
        console.log('🚀 ~ file: sell.vue ~ line 272 ~ selGood ~ this.goodItems', this.goodItems)
        return
        // var q = this.goods.filter((a) => a.good == e.good)[0].quantity
        // if (q < gi[0].quantity + 1) {
        //   return
        // }

        // gi[0].quantity++
      }
      /*
                 if (!!this.goodItems[item].good && this.goodItems[item].good.length > 0) {
                 this.goodItems[item].good.forEach(function (f) {
                 if (f.good == e.good) {
                 f.quantity++;
                 i++;
                 }
                 });
                 }*/
      if (gi.length == 0) {
        t.$set(e, 'quantity', '1')
        var f = JSON.parse(JSON.stringify(e))
        t.$set(t.goodItems, t.goodItems.length, f)
      }
      // t.saveGoodItems(true)
    },
    save: function(reload = true) {
      //Сохранение всех работ
      var t = this
      var j = JSON.stringify(this.goodItems)
      console.log('goodItems', this.goodItems)
      console.log('goodItems2', j)
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'good/sellSave/', {
          job: t.job,
          smena: t.smena.id,
          good: j,
          account: t.filterCostTpe,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              if (reload) {
                // console.log('reload', reload)
                t.$emit('close')
                t.$emit('reload')
                // t.loadData()
              }
              //
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    delGoodRow(e) {
      // удаление работы
      this.goodItems.splice(e, 1)
      this.goodItems = this.goodItems.filter(function(item) {
        return item
      })
      this.saveGoodItems(false)
    },
    loadGood(val) {
      var t = this
      t.$http
        .post(t.$store.state.apiUrl + 'good/SearchGood/', {
          text: val,
        })
        .then(
          response => {
            t.goods = response.body
            console.log('goods', t.goods)
            t.isLoading = false
          },
          err => {
            console.log(err)
          },
        )
    },

    itemCost: function(id) {
      let cost = 0,
        t = this
      console.log('itemCost', id)
      if (id == undefined) {
        id = t.clickServiceModalItem
      }
      if (id === undefined || id === '') {
        console.log('return 0 ', id)
        return 0
      }
      console.log('itemCost', id)
      t.items[id].service.forEach(function(entry) {
        cost += parseFloat(entry.cost) * parseFloat(entry.quantity)
      })
      console.log('cost', cost)
      return cost
    },
  },
}
</script>

<style></style>
