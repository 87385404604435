<template>
  <div>
    <div v-if="smena.id == ''">
      <h3>Смена не открыта</h3>
      <v-btn color="success" small @click="open">Открыть смену</v-btn>
    </div>
    <div v-if="smena.id > 0">
      <h3>
        Смена №{{ smena.num }}, открыл {{ smena.user_fio }} в
        {{ smena.start }}
      </h3>
      <v-row>
        <v-col md="9">
          <v-row>
            <v-col sm="3">
              <v-card outlined rounded>
                <v-card-title>Финансы</v-card-title>
                <v-card-text>
                  <table>
                    <tbody>
                      <tr v-for="item in smena_smm" :key="item.id">
                        <td>{{ item.account_name }}</td>
                        <td>
                          <span class="font-weight-bold">
                            {{ item.smm_finish }}
                          </span>
                        </td>
                        <td>
                          <span
                            :class="['caption', item.smm_finish - item.smm_start < 0 ? 'red--text' : 'green--text']"
                          >
                            {{ item.smm_finish - item.smm_start > 0 ? ' +' : ' -' }}
                            {{ item.smm_finish - item.smm_start }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
                <v-card-actions>
                  <v-btn text small color="info" @click="modalStatFinice = true">
                    Подробнее
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col sm="6">
              <v-card outlined rounded>
                <v-card-title>Зарплата</v-card-title>
                <v-card-text>
                  <v-simple-table dense>
                    <thead>
                      <tr>
                        <th class="text-left">Сотрудник</th>
                        <th class="text-left" width="100px">Начислено</th>
                        <th class="text-left" width="100px">Итого</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in worker_smm"
                        :key="item.id"
                        @click="
                          modalWorkerData = item
                          modalWorker = true
                        "
                        style="cursor: pointer"
                      >
                        <td>{{ item.worker_fio }}</td>
                        <td>{{ item.smena_smm }}</td>
                        <td>{{ item.total_smm }}</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="3">
              <v-card outlined rounded>
                <v-card-title>Информация</v-card-title>
                <v-card-text>
                  <table>
                    <tbody>
                      <tr>
                        <td>Новых заказов</td>
                        <td>0</td>
                      </tr>
                      <tr>
                        <td>Выполненых заказов</td>
                        <td>0</td>
                      </tr>
                    </tbody>
                  </table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col md="3">
          <v-card>
            <v-card-text>
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn block color="primary" v-on="on" class="mb-3">
                    Кассовые операции
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="modalCashMinusOpen">
                    <v-list-item-title>Выдать</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="modalCashInkasOpen">
                    <v-list-item-title>Инкасация</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="modalCashMoveOpen">
                    <v-list-item-title>Переместить</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="modalCashAddOpen">
                    <v-list-item-title>Пополнить</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn block outlined color="success" class=" mb-3" @click="flgGoodSell = true">
                Продать товар
              </v-btn>
              <v-btn block color="error" @click="close" class="mb-3">
                Закрыть смену
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <h4>Операции с деньгами</h4>
      <v-simple-table dense>
        <thead>
          <tr>
            <th class="text-left">Дата</th>
            <th class="text-left">Пользователь</th>
            <th class="text-left">Примечание</th>
            <th class="text-left" width="100px">Сумма</th>
            <th class="text-left" width="20px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in smm" :key="item.id">
            <td>{{ item.dte }}</td>
            <td>{{ item.user_fio }}</td>
            <td>
              {{ item.action_name }} {{ item.account_name }}
              <small>{{ item.comment }}</small>
            </td>
            <td>{{ item.smm }}</td>
            <td>
              <v-btn icon small @click="delSmm(item, index)"><v-icon>fa-trash-o</v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <goodList v-on:reload="infoLoad" v-bind:flgGoodReload="flgGoodReload"></goodList>
    </div>
    <v-dialog v-model="modalStatFinice">
      <v-card>
        <v-card-title class="headline"> Детализация по движению денег</v-card-title>
        <v-card-text>
          <h4>Счета</h4>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">Счет</th>
                <th class="text-left">Сумма на начало смены</th>
                <th class="text-left">Пополнение</th>
                <th class="text-left">Выдача</th>
                <th class="text-left">Выручка</th>
                <th class="text-left" width="100px">Итого</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in smena_smm" :key="item.id">
                <td>{{ item.account_name }}</td>
                <td>{{ item.smm_start }}</td>
                <td>{{ item.smm_add }}</td>
                <td>{{ item.smm_minus }}</td>
                <td>{{ item.smm_profit }}</td>
                <td>
                  <b>{{ item.smm_finish }}</b>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="modalStatFinice = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalWorker" scrollable>
      <v-card>
        <v-card-title class="headline"
          >Подробная информация по сотруднику {{ modalWorkerData.worker_fio }}</v-card-title
        >
        <v-card-text>
          <h4>Информация по смене</h4>
          Сумма на начало смены:{{ modalWorkerData.smena_smm }}<br />

          <h4>Операции</h4>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-left">Дата</th>
                <th class="text-left">Пользователь</th>
                <th class="text-left">Операция</th>
                <th class="text-left" width="100px">Сумма</th>
                <th class="text-left" width="20px"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in worker_detail" :key="item.id">
                <td>{{ item.dte }}</td>
                <td>{{ item.user_fio }}</td>
                <td>
                  {{ item.action_name }} {{ item.account_name }}
                  <small>{{ item.comment }}</small>
                </td>
                <td>{{ item.smm }}</td>
                <td>
                  <v-btn icon small @click="workerDelSmm(item, index)"><v-icon>fa-trash-o</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          Итого:{{ modalWorkerData.total_smm }}<br />
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="bonusAdd"> Премия </v-btn>
          <v-btn text @click="penaltyAdd"> Штраф </v-btn>
          <v-btn text @click="salaryPay"> Выдать </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="modalWorker = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalCashAdd" scrollable max-width="700">
      <form @submit.prevent="modalCashAddSave">
        <v-card>
          <v-card-title class="headline">Пополнить</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="accounts"
                  v-model="account"
                  label="Счет пополнения"
                  bottom
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="deposits"
                  v-model="deposit"
                  label="Группа"
                  bottom
                  item-text="name"
                  item-value="id"
                  return-object
                  width="200px"
                >
                  <v-btn text block slot="append-item" @click="editDeposit({})">
                    <v-icon color="green">fa-plus</v-icon> Добавить
                  </v-btn>
                  <div v-if="!!deposit" slot="append-outer" @click="editDeposit(deposit)">
                    <v-icon color="green">fa-pencil</v-icon>
                  </div>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="modalCashSmm" label="Сумма пополнения"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="modalCashText" label="Комментарий"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modalCashAdd = false"> Закрыть </v-btn>
            <v-btn color="primary" type="submit">> Сохранить </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <v-dialog v-model="modalCashMinus" scrollable max-width="700">
      <form @submit.prevent="modalCashMinusSave">
        <v-card>
          <v-card-title class="headline">Выдать</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="accounts"
                  v-model="account"
                  label="Счет пополнения"
                  bottom
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="payments"
                  v-model="payment"
                  label="Группа"
                  bottom
                  item-text="name"
                  item-value="id"
                  return-object
                  width="200px"
                >
                  <v-btn text block slot="append-item" @click="editPayment({})">
                    <v-icon color="green">fa-plus</v-icon> Добавить
                  </v-btn>
                  <div v-if="!!payment" slot="append-outer" @click="editPayment(payment)">
                    <v-icon color="green">fa-pencil</v-icon>
                  </div>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="modalCashSmm" label="Сумма к выдаче"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="modalCashText" label="Комментарий"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modalCashMinus = false"> Закрыть </v-btn>
            <v-btn type="submit" color="primary"> Сохранить </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <v-dialog v-model="modalCashMove" scrollable max-width="700">
      <form @submit.prevent="modalCashMoveSave">
        <v-card>
          <v-card-title class="headline">Переместить</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="accounts"
                  v-model="account"
                  label="Счет списания"
                  bottom
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="accounts"
                  v-model="account2"
                  label="Счет пополнения"
                  bottom
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="modalCashSmm" label="Сумма к выдаче" hide-details></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="modalCashText" label="Комментарий" hide-details></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modalCashMove = false"> Закрыть </v-btn>
            <v-btn color="primary" type="submit"> Сохранить </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <v-dialog v-model="modalCashInkas" scrollable max-width="700">
      <form @submit.prevent="modalCashInkasSave">
        <v-card>
          <v-card-title class="headline">Инкасация</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  hide-details
                  v-bind:items="accounts"
                  v-model="account"
                  label="Счет списания"
                  bottom
                  item-text="name"
                  item-value="id"
                ></v-select>
              </v-col>
              <v-col>
                <v-text-field v-model="modalCashText" label="Комментарий" hide-details></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field v-model="modalCashSmm" label="Сумма к выдаче"></v-text-field>
              </v-col>
              <v-col></v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="modalCashInkas = false"> Закрыть </v-btn>
            <v-btn color="primary" type="submit"> Сохранить </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
    <goodsell
      v-bind:flgOpen="flgGoodSell"
      v-on:close="flgGoodSell = false"
      v-on:reload="flgGoodReload = true"
    ></goodsell>
  </div>
</template>

<script>
import goodList from './good.vue'
import goodsell from './good_sell.vue'
import edit from './../../components/edit.vue'
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const messageBoxE = create(edit)
const messageBox = create(confirm)
export default {
  data() {
    return {
      smena: [],
      smm: [],
      smena_smm: [],
      worker_smm: [],
      worker_smm_detail: [],
      modalCashAdd: false,
      modalCashMinus: false,
      modalCashMove: false,
      modalCashInkas: false,
      modalWorker: false,
      modalWorkerData: '',
      modalCashSmm: '',
      modalCashText: '',
      account: '',
      account2: '',
      deposits: [],
      deposit: false,
      payments: [],
      payment: false,
      modalStatFinice: false,
      flgGoodSell: false,
      flgGoodReload: false,
    }
  },
  components: { goodList, goodsell },
  computed: {
    accounts: function() {
      return this.$store.state.spr.account
    },
    worker_detail: function() {
      var id = this.modalWorkerData.worker
      console.log('this.worker_smm_detail', this.worker_smm_detail)
      if (this.worker_smm_detail == null) {
        return []
      }
      return this.worker_smm_detail.filter(function(e) {
        return e.worker == id
      })
    },
  },
  mounted: function() {
    this.infoLoad()
    this.depositLoad()
    this.paymentLoad()

    this.$store.dispatch('loadAccount')
  },
  methods: {
    bonusAdd: function() {
      var t = this
      //                        console.log('row',row)
      messageBoxE({
        urlSave: 'smena/smmWorkerSave/',
        //                            id: ,
        fields: [{ type: 'input', text: 'Сумма', name: 'smm', value: 0 }],
        prm: [
          { name: 'action', value: 4 },
          { name: 'smena', value: t.smena.id },
          { name: 'worker', value: t.modalWorkerData.worker },
        ],
        title: 'Начисление бонуса сотруднику: ' + t.modalWorkerData.worker_fio,
      })
        .transition()
        .then(response => {
          if (response) {
            t.infoLoad()
          }
          console.log('response', response)
        })
    },
    penaltyAdd: function() {
      var t = this
      //                        console.log('row',row)
      messageBoxE({
        urlSave: 'smena/smmWorkerSave/',
        //                            id: ,
        fields: [{ type: 'input', text: 'Сумма', name: 'smm', value: 0 }],
        prm: [
          { name: 'action', value: 5 },
          { name: 'smena', value: t.smena.id },
          { name: 'worker', value: t.modalWorkerData.worker },
        ],
        title: 'Начисление штрафа сотруднику: ' + t.modalWorkerData.worker_fio,
      })
        .transition()
        .then(response => {
          if (response) {
            t.infoLoad()
          }
          console.log('response', response)
        })
    },
    salaryPay: function() {
      var t = this
      //                        console.log('row',row)
      messageBoxE({
        urlSave: 'smena/smmWorkerSave/',
        //                            id: ,
        fields: [{ type: 'input', text: 'Сумма', name: 'smm', value: 0 }],
        prm: [
          { name: 'action', value: 3 },
          { name: 'smena', value: t.smena.id },
          { name: 'worker', value: t.modalWorkerData.worker },
        ],
        title: 'Выплата сотруднику: ' + t.modalWorkerData.worker_fio,
      })
        .transition()
        .then(response => {
          if (response) {
            t.infoLoad()
          }
          console.log('response', response)
        })
    },
    infoLoad: function() {
      var t = this
      t.flgGoodReload = false
      this.$http.post(this.$store.state.apiUrl + 'smena/info').then(
        response => {
          if (response.body.err > 0) {
            console.log(response.body)
          } else {
            t.smena = response.body.smena
            t.smm = response.body.smm
            t.smena_smm = response.body.smena_smm
            t.worker_smm = response.body.worker_smm
            t.worker_smm_detail = response.body.worker_smm_detail
            if (t.smena.id == '') {
              t.smena.num = 'close'
            }
            t.$store.commit('setSmena', t.smena)
          }
          //                            console.log(t.smena);
        },
        err => {
          console.log(err)
        },
      )
    },
    open: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'smena/start/').then(
        response => {
          t.$store.dispatch('setMessage', {
            type: response.body.err == 1 ? 'error' : 'success',
            message: response.body.msg,
          })
          console.log(!response.body.err)
          if (!response.body.err) {
            t.infoLoad()
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    close: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/finish/', {
          smena: t.smena.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.$store.commit('setSmena', {
                id: '',
                num: '',
              })
              t.infoLoad()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    modalCashAddOpen: function() {
      var t = this
      t.account = ''
      t.deposit = ''
      t.modalCashSmm = ''
      t.modalCashText = ''
      t.modalCashAdd = true
    },
    modalCashAddSave: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/smmEdit/', {
          smena: t.smena.id,
          smm: t.modalCashSmm,
          comment: t.modalCashText,
          deposit: t.deposit.id,
          action: 2,
          account: t.account,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.infoLoad()
              t.modalCashAdd = false
            }
          },
          err => {
            console.log(err)
          },
        )
      t.modalCashSmm = ''
      t.modalCashText = ''
    },
    modalCashMinusOpen: function() {
      var t = this
      t.account = ''
      t.payment = ''
      t.modalCashSmm = ''
      t.modalCashText = ''
      t.modalCashMinus = true
    },
    modalCashMinusSave: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/smmEdit/', {
          smena: t.smena.id,
          smm: t.modalCashSmm,
          comment: t.modalCashText,
          payment: t.payment.id,
          action: 3,
          account: t.account,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.infoLoad()
              t.modalCashMinus = false
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    modalCashInkasOpen: function() {
      var t = this
      t.account = ''
      t.modalCashSmm = ''
      t.modalCashText = ''
      t.modalCashInkas = true
    },
    modalCashInkasSave: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/smmInkas/', {
          smena: t.smena.id,
          smm: t.modalCashSmm,
          comment: t.modalCashText,
          account: t.account,
          account2: t.account2,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.infoLoad()
              t.modalCashInkas = false
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    modalCashMoveOpen: function() {
      var t = this
      t.account = ''
      t.account2 = ''
      t.modalCashSmm = ''
      t.modalCashText = ''
      t.modalCashMove = true
    },
    modalCashMoveSave: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/smmMove/', {
          smena: t.smena.id,
          smm: t.modalCashSmm,
          comment: t.modalCashText,
          from: t.account,
          to: t.account2,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.infoLoad()
              t.modalCashMove = false
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    delSmm: function(e) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'smena/smmDel/', {
          id: e.id,
        })
        .then(
          response => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              t.infoLoad()
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    workerDelSmm: function(e) {
      var t = this
      messageBox({
        text: 'Вы хотите удалить операцию ?',
        type: 'delete',
        title: 'Подтверждение удаления',
      })
        .transition()
        .then(response => {
          if (response) {
            this.$http
              .post(this.$store.state.apiUrl + 'smena/smmWorkerDel/', {
                id: e.id,
                smena: t.smena.id,
              })
              .then(
                response => {
                  t.$store.dispatch('setMessage', {
                    type: response.body.err == 1 ? 'error' : 'success',
                    message: response.body.msg,
                  })
                  if (!response.body.err) {
                    t.infoLoad()
                  }
                },
                err => {
                  console.log(err)
                },
              )
          }
        })
    },
    depositLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'smena/deposit').then(
        response => {
          if (!response.body.err) {
            t.deposits = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    paymentLoad: function() {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'smena/payment').then(
        response => {
          if (!response.body.err) {
            t.payments = response.body
          }
        },
        err => {
          console.log(err)
        },
      )
    },
    editDeposit: function(group) {
      var t = this
      messageBoxE({
        urlSave: 'smena/depositSave/',
        urlDel: 'smena/depositDelete/',
        id: group.id,
        fields: [{ type: 'input', text: 'Название', name: 'name', value: group.name }],
        title: 'Редактирование группы',
      })
        .transition()
        .then(response => {
          if (response) {
            t.depositLoad()
          }
          console.log('response', response)
        })
    },
    editPayment: function(group) {
      var t = this
      messageBoxE({
        urlSave: 'smena/paymentSave/',
        urlDel: 'smena/paymentDelete/',
        id: group.id,
        fields: [{ type: 'input', text: 'Название', name: 'name', value: group.name }],
        title: 'Редактирование группы',
      })
        .transition()
        .then(response => {
          if (response) {
            t.paymentLoad()
          }
          console.log('response', response)
        })
    },
  },
}
</script>

<style></style>
