<template>
  <div>
    <h4>Проданные товары</h4>
    <v-simple-table dense>
      <thead>
        <tr>
          <th class="text-left">Дата</th>
          <th class="text-left">Название</th>
          <th class="text-left">Заказ</th>
          <th class="text-left">Тип оплаты</th>
          <th class="text-left">Кол-во</th>
          <th class="text-left">Стоимость</th>
          <th class="text-left">Итого</th>
          <th class="text-left" width="20px"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in row" :key="index">
          <td>{{ item.dte }}</td>
          <td>{{ item.name }}</td>
          <td>
            <a href="#" @click="openJob(item.job)">{{ item.job_name }}</a>
          </td>
          <td>{{ item.account_name }}</td>
          <td>{{ item.quantity }}</td>
          <td>{{ item.cost }}</td>
          <td>{{ parseFloat(item.quantity) * parseFloat(item.cost) }}</td>
          <td>
            <v-btn icon small @click="delGood(item)" v-if="!item.job"><v-icon>fa-trash-o</v-icon></v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <job_edit v-if="modal" v-bind:view="modalView" v-on:close="modalClose"></job_edit>
  </div>
</template>

<script>
import confirm from './../../components/confirm.vue'
import { create } from 'vue-modal-dialogs'
const messageBox = create(confirm)

export default {
  props: ['flgGoodReload'],
  components: {
    job_edit: () => import('./../job/job_edit.vue'),
  },
  data() {
    return {
      row: [],
      modal: '',
      modalView: [],
    }
  },
  watch: {
    flgGoodReload(val) {
      if (val) {
        this.goodLoad()
        this.$emit('reload')
      }
    },
  },
  computed: {
    smena: function() {
      return this.$store.state.auth.smena
    },
  },
  mounted: function() {
    var t = this
    t.goodLoad()
  },
  methods: {
    goodLoad: function() {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'good/sell', {
          smena: t.smena.id,
        })
        .then(
          response => {
            if (response.body.err > 0) {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: response.body.msg,
              })
            } else {
              t.row = response.body
            }
          },
          err => {
            console.log(err)
          },
        )
    },
    openJob: function(view) {
      console.log({ id: view })
      this.modal = true
      this.modalView = { id: view }
      return false
    },
    modalClose: function() {
      this.modal = ''
    },
    delGood: function(item) {
      var t = this
      //                console.log(t.data)
      messageBox({
        text:
          'Вы на самом деле хотите отменить продажу товара: ' + item.name + ' на сумму: ' + item.quantity * item.cost,
        type: 'delete',
        title: 'Удаление продажи товара',
      })
        .transition()
        .then(response => {
          if (response) {
            t.delSave(item.id)
          }
        })
    },
    delSave: function(id) {
      var t = this
      t.$store.commit('loadingStartShow', true)
      this.$http
        .post(this.$store.state.apiUrl + 'good/sellDelete/', {
          id: id,
        })
        .then(
          response => {
            t.$store.commit('loadingStartShow', false)
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              t.goodLoad()
              t.$emit('reload')
            }
          },
          err => {
            console.log(err)
          },
        )
    },
  },
}
</script>

<style></style>
